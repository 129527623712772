@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url(https://use.fontawesome.com/releases/v5.0.13/css/all.css);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body,html{
  font-family: 'Open Sans', sans-serif;
}
button{-webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background:none;
  border:none;
  outline:none;
  cursor: pointer;
}
/***************************************/

$orange: #F4811F;

.App{
  background: url('../images/background-home.jpg') #000000 no-repeat center 0;
  padding-bottom: 100px;
}

.header-brand{
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

#content{
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
}

#content__main{
  width: 70%;
  min-height: 500px;
  background-color: #ffffff;
  border-radius: 5px;
  moz-border-radius: 5px;
  webkit-border-radius: 5px;
  padding-bottom: 50px;
}

#contend__sidebar{
  width: 30%;
  min-height: 500px;;
}

#contend__sidebar-inner{
  padding-left: 20px;
}

#content__inner{
  padding: 40px 30px;
}

.title{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.select-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.select-container__select{
  width: 60%;
}

.select-container__price{
  padding-left: 20px;
  text-align: right;
}

.select-container__value{
  font-size: 25px;
  color: $orange;
  font-weight: 600;
  display: block;
}

.select-container__periodity{
  font-size: 13px;
  color: #9B9B9B;
}

.plan__description{
  margin: 10px 0 30px 0;
  font-size: 10px;
  line-height: 16px;
  padding: 15px;
  border: 1px solid #DADADA;
  border-radius: 5px;
  moz-border-radius: 5px;
  webkit-border-radius: 5px;
}

.wrapper{
  margin-bottom: 30px;
}

.inner{
  padding: 0 5px;
}

.group{
  display: flex;
  justify-content: space-between;
}

.col-5{
  width: 50%;
}

.col-3{
  width: 33.33%;
}

.col-7{
  width: 70%;
}

.radio-box{
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
}

.radio-button{
  width: 20px;
  height: 20px;
  border-radius: 26px;
  moz-border-radius: 26px;
  webkit-border-radius: 26px;
  border: 1px solid #CCCCCC;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.radio-box.ativo .radio-button{
  background-color: $orange;
  border-color: $orange;
  span{
    background-color: #ffffff;
    width: 10px;
    height: 10px;
    border-radius: 26px;
    moz-border-radius: 26px;
    webkit-border-radius: 26px;
    display: block;
  }
}

.bx-bt-enviar{
  text-align: center;
}

.box-radio{
  margin-bottom: 20px;
}

.img-bt-submit{
  width: 100%;
}

.type-pay-txt{
  font-size: 12px;

  button{
    font-weight: 500;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    text-decoration: underline;
  }
}

.li_concordo{
  padding-top: 20px;
}


.box-bandeiras{
  margin: 15px 0;
  span{
    display: inline-block;
    width: 15%;
  }
  a{
    display: inline-block;

    margin: 0 3px;
    img{
      width: 100%;
      border: 1px solid #cccccc;
    }
    opacity: .5;
  }
  a.ativo{
    opacity: 1;
    img{
      border-color: $orange;
    }
  }
}

.list-boleto{
  li{
    list-style-type: disc;
    margin-bottom: 10px;
    font-size: 14px;

    strong{
      font-weight: 600;
    }
  }
  padding: 20px 0 0 20px;
}

.title-boleto{
  padding: 20px 0 15px 0;
}

.termo-wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.7);
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.termo-box{
  width: 90%;
  height: 80%;
  position: relative;
}

.header-termos{
  position: absolute;
  width: 100%;
  z-index: 3;
  height: 90px;
  background-color: #000000;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bt-close-termos{
  color: #ffffff;
  padding: 5px 15px;
  cursor: pointer;
}

div.select__control{
  min-height: 43px;
  border-color: #DADADA;
}

.box-cvc{
  position: relative;
}

.cvc-desc{
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 10px;
  background: #dddddd;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -50%);
}

/* Tooltip container */
.tooltip {
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  #content__main{
    width: 100%;
  }
  #contend__sidebar{
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  #content{
    flex-direction: column;
  }
  .col-7,
  .col-3,
  .col-5{
    width: 100%;
  }
  .select-container,
  .group{
    display: block;
  }
  .select-container__select{
    width: 100%;
  }
  #content__inner {
    padding: 40px 10px;
  }
}


a.bt-assessoria-whats {
  text-decoration: none;
  background: #1dd741;
  padding: 10px 20px;
  border-radius: 10px;
  display: inline-block;
}

.bt-assessoria-whats .fab, .bt-assessoria-whats .link-menu--txt {
  font-size: 17px;
  line-height: 20px;
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

.bt-assessoria-whats .fab {
    margin-right: 10px;
}