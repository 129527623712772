.cliente{
    margin-bottom: 30px;
}

.cliente__name{
    display: block;
    text-align: center;
    margin: 10px 0 0 0;
    color: #ffffff;
    font-weight: 600;
}