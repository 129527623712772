
.field__input{
    border: none;
    font-size: 14px;
    background: none;
    width: 100%;
    outline: none;
    font-family: 'Open Sans', sans-serif;
  }
  
  .field__content{
    border: 1px solid #DADADA;
    border-radius: 5px;
    moz-border-radius: 5px;
    webkit-border-radius: 5px;
    padding: 10px;
    align-items: center;
    display: flex;
  }

  .field__icon{
      width: 20px;
      margin-right: 10px;
  }

  .field__input-box{
      flex: 1;
  }

  .field{
    margin-bottom: 10px;
  }
  